import { debounce } from 'lodash';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useCallback, useEffect, useState } from 'react';

import { SearchInput } from '@/components/search-input';

type Props = {
  total: number;
};

export const UserFilter: React.FC<Props> = ({ total }) => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const q = searchParams.get('q') ?? '';
  const [keyword, setKeyword] = useState('');

  const searchHandler = useCallback(
    (value: string) => {
      const keyword = value.trim().toLowerCase();
      const newSearchParams = new URLSearchParams(searchParams.toString());
      if (keyword) {
        newSearchParams.set('q', keyword);
      } else {
        newSearchParams.delete('q');
      }
      newSearchParams.sort();
      router.push([pathname, newSearchParams.toString()].filter(Boolean).join('?'));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname, searchParams],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchHandler = useCallback(debounce(searchHandler, 500), [searchHandler]);

  const handleKeywordChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const newValue = event.currentTarget.value ?? '';
    setKeyword(newValue);
    debouncedSearchHandler(newValue);
  };

  useEffect(() => {
    if (q) setKeyword(q);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="grid grid-flow-row items-center justify-between gap-4 md:grid-flow-col">
      <div className="inline-flex items-end gap-x-2">
        <h2 className="text-xl font-semibold">Users</h2>
        {total ? <span className="text-sm text-grey-dark">Showing {total} users</span> : null}
      </div>
      <SearchInput
        name="q"
        placeholder="Search user..."
        value={keyword}
        onChange={handleKeywordChange}
        compact
      />
    </div>
  );
};
