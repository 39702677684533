import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSearchParams } from 'next/navigation';
import { toast } from 'sonner';

import { closeDialog } from '@/components/dialog';
import { request } from '@/libs/request';

import { resetKybKycPassForm } from '../model';

type SetKybKycPassParams = {
  userId: string;
};

type SetKybKycPassBody = {
  comments?: string;
};

export const useSetKybKycPass = () => {
  const client = useQueryClient();
  const searchParams = useSearchParams();

  return useMutation({
    mutationKey: ['SetKybKycPass'],
    mutationFn: async (params: SetKybKycPassParams & SetKybKycPassBody) => {
      const { userId, comments } = params;
      await request.post(`/users/${userId}/background-check/manual-pass`, {
        comments,
      });
    },
    onSuccess() {
      closeDialog();
      resetKybKycPassForm();
      client.invalidateQueries({
        queryKey: ['GetUsers', searchParams.get('q')],
      });
    },
    onError(error) {
      toast.error(error instanceof Error ? error.message : 'Failed to set KYB/KYC pass.');
    },
  });
};
