import { cva, type VariantProps } from 'class-variance-authority';
import { forwardRef } from 'react';
import TextareaAutosize, { type TextareaAutosizeProps } from 'react-textarea-autosize';
import { twMerge } from 'tailwind-merge';

const textareaVariants = cva(
  'w-full rounded border border-grey-tertiary bg-white px-3.5 py-4 text-base text-black outline-none transition-colors duration-150 ease-linear placeholder:text-grey-dark focus:border-blue focus:shadow-field disabled:cursor-not-allowed disabled:bg-grey-light disabled:text-grey-tertiary disabled:placeholder:text-grey-tertiary',
  {
    variants: {
      changed: {
        true: 'border-purple',
      },
      error: {
        true: 'border-pink focus:border-pink focus:shadow-field-error',
      },
    },
  },
);

export interface TextareaProps
  extends VariantProps<typeof textareaVariants>,
    TextareaAutosizeProps {
  changed?: boolean;
  error?: boolean;
  selectOnFocus?: boolean;
  scrollIntoViewOnFocus?: boolean;
  removeLineBreaks?: boolean;
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      changed,
      error,
      selectOnFocus,
      scrollIntoViewOnFocus = true,
      removeLineBreaks,
      minRows,
      maxRows,
      cacheMeasurements,
      hidden,
      className,
      onChange,
      onFocus,
      onHeightChange,
      ...restProps
    },
    ref,
  ) => {
    if (hidden) {
      return null;
    }

    // FIXME: There's a issue when using with `removeLineBreaks` prop
    // The height of textarea is not calculated correctly when click Enter
    const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> = (event) => {
      if (removeLineBreaks) {
        const targetValue = event.currentTarget.value;
        event.currentTarget.value = targetValue.replaceAll('\n', ' ');
      }
      onChange?.(event);
    };

    const handleFocus: React.FocusEventHandler<HTMLTextAreaElement> = (event) => {
      if (selectOnFocus) event.currentTarget.select();
      if (scrollIntoViewOnFocus) {
        const element = event.currentTarget;
        setTimeout(() => {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }, 300);
      }
      onFocus?.(event);
    };

    return (
      <TextareaAutosize
        ref={ref}
        minRows={minRows}
        maxRows={maxRows}
        cacheMeasurements={cacheMeasurements}
        onChange={handleChange}
        onFocus={handleFocus}
        onHeightChange={onHeightChange}
        className={twMerge(
          textareaVariants({
            changed,
            error,
            className,
          }),
        )}
        {...restProps}
      />
    );
  },
);

Textarea.displayName = 'Textarea';
