import { SearchIcon } from 'lucide-react';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { Input, type InputProps } from '../input';

export type SearchInputProps = Omit<InputProps, 'type' | 'prefix' | 'transparentPrefix'>;

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  ({ className, inputClassName, ...restProps }, ref) => {
    return (
      <Input
        ref={ref}
        {...restProps}
        type="search"
        prefix={<SearchIcon />}
        transparentPrefix
        className={twMerge('rounded-full border-grey-hover bg-grey-light', className)}
        inputClassName={twMerge('rounded-full bg-grey-light', inputClassName)}
      />
    );
  },
);

SearchInput.displayName = 'SearchInput';
