import * as DialogPrimitive from '@radix-ui/react-dialog';
import { XIcon } from 'lucide-react';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

export const DialogRoot = DialogPrimitive.Root;
export const DialogTrigger = DialogPrimitive.Trigger;
export const DialogPortal = DialogPrimitive.Portal;
export const DialogTitle = DialogPrimitive.Title;
export const DialogDescription = DialogPrimitive.Description;

export const DialogClose = forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement>
>(({ hidden, className, ...restProps }, ref) => {
  if (hidden) {
    return null;
  }

  return (
    <DialogPrimitive.Close asChild>
      <button
        ref={ref}
        type="button"
        className={twMerge(
          'absolute right-[20px] top-[20px] flex size-8 cursor-pointer items-center justify-center rounded bg-transparent text-grey-dark transition-colors duration-150 ease-linear hover:bg-grey-light hover:text-blue sm:right-[30px] sm:top-[30px]',
          className,
        )}
        {...restProps}
      >
        <XIcon className="size-5 text-current" />
      </button>
    </DialogPrimitive.Close>
  );
});

export const DialogOverlay = forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...restProps }, ref) => {
  return (
    <DialogPrimitive.Overlay
      ref={ref}
      className={twMerge('fixed inset-0 z-[400] bg-black/50', className)}
      {...restProps}
    />
  );
});

export const DialogContent = forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
>(({ className, ...restProps }, ref) => {
  return (
    <DialogPrimitive.Content
      ref={ref}
      className={twMerge(
        'fixed left-1/2 top-1/2 z-[401] max-h-[85vh] w-[90vw] max-w-[400px] -translate-x-1/2 -translate-y-1/2 overflow-auto rounded-2xl rounded-tl-none bg-white px-5 py-10 shadow-lg focus:outline-none sm:px-[30px]',
        className,
      )}
      {...restProps}
    />
  );
});

DialogClose.displayName = 'DialogClose';
DialogOverlay.displayName = 'DialogOverlay';
DialogContent.displayName = 'DialogContent';
