import { useInfiniteQuery } from '@tanstack/react-query';
import { useSearchParams } from 'next/navigation';

import { request } from '@/libs/request';
import { type User } from '@/models/user';

type GetUsersQueryString = {
  page?: number;
  size?: number;
  searchTerm?: string;
  sortBy?: string;
  ordering?: 'ASC' | 'DESC';
};

type GetUsersResponse = {
  data: User[];
} | null;

const pageSize = 50;

export const useGetUsers = () => {
  const searchParams = useSearchParams();
  const q = searchParams.get('q');

  return useInfiniteQuery({
    queryKey: ['GetUsers', q],
    refetchOnWindowFocus: false,
    initialPageParam: 0,
    queryFn: async ({ pageParam }) => {
      const page = Math.floor(pageParam / pageSize);
      const params: GetUsersQueryString = { size: pageSize, page };
      if (q) params.searchTerm = q.trim();
      params.sortBy = 'createdAt';
      params.ordering = 'DESC';
      const { data } = await request.get<GetUsersResponse>('/users/search', {
        params,
        paramsSerializer: {
          encode: encodeURIComponent,
        },
      });

      return {
        data: data?.data ?? [],
        offset: pageParam,
      };
    },
    getNextPageParam: (lastPage) => {
      const { data, offset } = lastPage;
      return data.length < pageSize ? null : offset + pageSize;
    },
  });
};
