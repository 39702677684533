import { useEffect, useRef } from 'react';

type Props = {
  hasNextPage?: boolean;
  isFetching?: boolean;
  children: React.ReactNode;
  className?: string;
  fetchNextPage: () => void;
};

export const InfiniteScroll: React.FC<Props> = ({
  hasNextPage,
  isFetching,
  children,
  className,
  fetchNextPage,
}) => {
  const scrollIndicator = useRef<HTMLDivElement | null>(null);
  const canFetchNextPage = hasNextPage && !isFetching;

  useEffect(() => {
    const indicator = scrollIndicator.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (canFetchNextPage && entry?.isIntersecting) {
          fetchNextPage();
        }
      },
      {
        threshold: [1],
      },
    );

    if (indicator) {
      observer.observe(indicator);
    }

    return () => {
      if (indicator) {
        observer.unobserve(indicator);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canFetchNextPage]);

  return (
    <div className={className}>
      {children}
      <footer ref={scrollIndicator} className="py-6">
        {isFetching ? (
          <p className="text-center text-grey-dark">Loading more...</p>
        ) : hasNextPage ? null : (
          <p className="text-center text-grey-dark">No more data</p>
        )}
      </footer>
    </div>
  );
};
