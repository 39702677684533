import reactStringReplace from 'react-string-replace';
import { twMerge } from 'tailwind-merge';

export interface HighlightProps extends React.HTMLAttributes<HTMLSpanElement> {
  match?: string | RegExp;
  children?: React.ReactNode;
  className?: string;
}

export const Highlight: React.FC<HighlightProps> = ({
  match,
  children,
  className,
  ...restProps
}) => {
  if (
    typeof children === 'string' ||
    (Array.isArray(children) && children.every((node) => typeof node === 'string') && match)
  ) {
    return (
      <>
        {/* @ts-expect-error known types */}
        {reactStringReplace(children, match, (match, index) => (
          <span key={index} className={twMerge('bg-yellow-400', className)} {...restProps}>
            {match}
          </span>
        ))}
      </>
    );
  }

  return children;
};

Highlight.displayName = 'Highlight';
