import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSearchParams } from 'next/navigation';
import { toast } from 'sonner';

import { request } from '@/libs/request';

type ToggleUserActiveStatusParams = {
  userId: string;
};

export const useToggleActiveStatus = () => {
  const client = useQueryClient();
  const searchParams = useSearchParams();

  return useMutation({
    mutationKey: ['ToggleUserActiveStatus'],
    mutationFn: async (params: ToggleUserActiveStatusParams) => {
      const { userId } = params;
      await request.post(`/users/${userId}/active-status/toggle`);
    },
    onSuccess() {
      client.invalidateQueries({
        queryKey: ['GetUsers', searchParams.get('q')],
      });
    },
    onError(error) {
      toast.error(error instanceof Error ? error.message : 'Failed to toggle user status.');
    },
  });
};
