'use client';

import { Button } from '@/components/button';
import { InfiniteScroll } from '@/components/infinite-scroll';

import { useGetUsers } from '../../hooks/users';
import { UserCard } from '../user-card';
import { UserFilter } from '../user-filter';

export const UserList: React.FC = () => {
  const { data, hasNextPage, error, isLoading, isFetching, fetchNextPage, refetch } = useGetUsers();
  const users = (data?.pages ?? []).flatMap((page) => page.data);
  const numberOfUsers = users.length;

  const handleReload = () => {
    refetch();
  };

  if (error) {
    return (
      <div className="space-y-2">
        <p>Errors: {JSON.stringify(error)}</p>
        <Button variant="solid" size="sm" full={false} onClick={handleReload}>
          Reload
        </Button>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <UserFilter total={numberOfUsers} />
      {isLoading ? (
        <p className="text-center text-grey-dark">Loading users...</p>
      ) : numberOfUsers > 0 ? (
        <InfiniteScroll
          hasNextPage={hasNextPage}
          isFetching={isFetching}
          fetchNextPage={() => !isFetching && fetchNextPage()}
          className="space-y-4"
        >
          {users.map((user) => (
            <UserCard key={user.id} data={user} />
          ))}
        </InfiniteScroll>
      ) : (
        <p className="text-center text-grey-dark">No users found</p>
      )}
    </div>
  );
};
