import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useSnapshot } from 'valtio';
import { z } from 'zod';

import { Button } from '@/components/button';
import {
  Asterisk,
  Form,
  FormControl,
  FormField,
  FormHelper,
  FormItem,
  FormLabel,
} from '@/components/form';
import { Textarea } from '@/components/textarea';

import { useSetKybKycPass } from './hooks/kyb-kyc-pass';
import { kybKycPassFormManager } from './model';

export const KybKycPassForm: React.FC = () => {
  const { userId } = useSnapshot(kybKycPassFormManager);
  const { mutate, isPending } = useSetKybKycPass();

  const form = useForm({
    mode: 'onSubmit',
    defaultValues: {
      comments: '',
    },
    resolver: zodResolver(
      z.object({
        comments: z.string().min(1, 'Required field'),
      }),
    ),
  });

  const handleSubmit = form.handleSubmit(({ comments }) => {
    mutate({
      userId,
      comments,
    });
  });

  return (
    <Form {...form}>
      <form id="kybKycPassForm" onSubmit={handleSubmit} className="space-y-9">
        <p className="text-xl">Set KYB/KYC pass manually</p>
        <FormField
          name="comments"
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                Comments <Asterisk />
              </FormLabel>
              <FormControl>
                <Textarea
                  {...field}
                  minRows={5}
                  autoFocus
                  disabled={isPending}
                  error={false}
                  changed={false}
                />
              </FormControl>
              <FormHelper />
            </FormItem>
          )}
        />
        <Button
          size="lg"
          variant="solid"
          disabled={isPending || !form.formState.isValid}
          onClick={handleSubmit}
        >
          {isPending ? 'Submitting...' : 'Submit'}
        </Button>
      </form>
    </Form>
  );
};
