export enum UserRole {
  SOLE_DIRECTOR = 'SOLE_DIRECTOR',
  DIRECTOR = 'DIRECTOR',
  SECRETARY = 'SECRETARY',
  SOLE_TRADER = 'SOLE_TRADER',
  PARTNERSHIP = 'PARTNERSHIP',
  TRUSTEE = 'TRUSTEE',
  OWNER = 'OWNER',
  OTHER = 'OTHER',
}

export enum UserStatus {
  UNVERIFIED = 'UNVERIFIED',
  LIVE = 'LIVE',
}

export enum EntityStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum BusinessStructure {
  COMPANY = 'COMPANY',
  SOLE_TRADER = 'SOLE_TRADER',
  PARTNERSHIP = 'PARTNERSHIP',
  TRUST = 'TRUST',
  OTHER = 'OTHER',
}

export enum TrustType {
  COMPANY = 'COMPANY',
  INDIVIDUAL = 'INDIVIDUAL',
}

export enum BusinessType {
  BUILDER = 'BUILDER',
  SUPPLIER = 'SUPPLIER',
  SUBCONTRACTOR = 'SUBCONTRACTOR',
}

export enum CheckStatus {
  REQUESTED = 'REQUESTED',
  PASS = 'PASS',
  FAIL = 'FAIL',
  RESULT_READY = 'RESULT_READY',
  UNKNOWN = 'UNKNOWN',
}

export enum ProductReferenceCode {
  ONPLATFORM_PRIME_RBN_NEW_HOME = 'ONPLATFORM_PRIME_RBN_NEW_HOME',
  ONPLATFORM_PRIME_RBN_IMPROVEMENT = 'ONPLATFORM_PRIME_RBN_IMPROVEMENT',
  ONPLATFORM_SUB_PAID = 'ONPLATFORM_SUB_PAID',
  ONPLATFORM_SUPPLY_PAID = 'ONPLATFORM_SUPPLY_PAID',
}

export enum ProductType {
  CONTRACT = 'CONTRACT',
}
