import { proxy } from 'valtio';
import { devtools } from 'valtio/utils';

const initialState = {
  userId: '',
};

export const kybKycPassFormManager = proxy(initialState);

devtools(kybKycPassFormManager, {
  name: 'Set KYB/KYC Pass Form',
  enabled: false,
});

export const resetKybKycPassForm = () => {
  Object.assign(kybKycPassFormManager, initialState);
};

export const saveUserId = (userId: string) => {
  kybKycPassFormManager.userId = userId;
};
